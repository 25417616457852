import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogListing from "../components/blog/bloglisting.component"

export const blogPostListQueryByCategory = graphql`
  query blogPostListQueryByCategory(
    $category: String
    $skip: Int!
    $limit: Int!
  ) {
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { category: { name: { in: [$category] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          tags
          title
          publishDate(formatString: "DD MMMM, YYYY")
          blogImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          category {
            name
          }
          body {
            childMarkdownRemark {
              excerpt
              html
            }
          }
        }
      }
    }
  }
`

const BlogListCategory = ({ data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges
  const {
    currentPage,
    numPages,
    link,
    categories,
    tags,
    category,
  } = pageContext

  return (
    <Layout>
      <SEO
        title={`Blog listing for category - ${category} | Page ${currentPage}`}
      />
      <BlogListing
        posts={posts}
        currentPage={currentPage}
        numPages={numPages}
        link={link}
        categories={categories}
        tags={tags}
      />
    </Layout>
  )
}

export default BlogListCategory
